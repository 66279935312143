import { css } from 'styled-components'
import { breakpoints, respondTo } from './media'
import { spacings } from './spacings'

import { dimensions, fonts, colors } from './variables'

export const getEmSize = (size: number) => size / dimensions.fontSize.regular

type FontFn = (fontSize?: number | null, fontWeight?: number | null, lineHeight?: string | null, letterSpacing?: string | null) => string

const font: FontFn = (fontSize, fontWeight, lineHeight, letterSpacing) => {
  return `
    font-size: ${fontSize || 16}px;
    font-weight: ${fontWeight || 400};
    ${lineHeight ? `line-height: ${lineHeight};` : ''}
    ${letterSpacing ? `letter-spacing: ${letterSpacing};` : ''}
  `
}

const sourceCode: FontFn = (fontSize, fontWeight, lineHeight, letterSpacing) => {
  return `
    font-family: ${fonts.sourceCode};
    ${font(fontSize, fontWeight, lineHeight, letterSpacing)}
  `
}

const proximaNova: FontFn = (fontSize, fontWeight, lineHeight, letterSpacing) => {
  return `
    font-family: 'Proxima Nova', sans-serif;
    ${font(fontSize, fontWeight, lineHeight, letterSpacing)}
  `
}

const abel: FontFn = (fontSize, fontWeight, lineHeight, letterSpacing) => {
  return `
    font-family: 'Abel', sans-serif;
    ${font(fontSize, fontWeight, lineHeight, letterSpacing)}
  `
}

export const getFont = (family: 'source_code' | 'abel' | 'proxima_nova') => {
  const font = family === 'source_code' ? fonts.sourceCode : family === 'abel' ? 'Abel' : 'Proxima Nova'
  return `font-family: ${font};`
}

export { colors }

export const typo = {
  sourceCode,
  proximaNova,
  abel,
  font
}

export const gutter = 17

export function container(wide: boolean = false) {
  return `
    ${!wide && 'max-width: 1240px'};
    margin: 0 auto;
  `
}

type Align = 'flex-start' | 'flex-end' | 'center' | null
type Justify = 'space-between' | 'space-around' | 'flex-start' | 'flex-end' | 'center' | null
type Wrap = 'wrap' | 'nowrap' | null

export function flexRow(justify?: Justify, align?: Align, wrap?: Wrap) {
  return `
    display: flex;
    ${justify ? `justify-content:${justify};` : ''}
    ${align ? `align-items:${align};` : ''}
    ${wrap ? `flex-wrap:${wrap};` : ''}
  `
}

export function flexColumn(justify?: Justify, align?: Align) {
  return `
    flex-direction: column;
    ${flexRow(justify, align)}
  `
}

export function grid(cols = 12, responsive = true) {
  return `
        display: grid;
        grid-gap: ${gutter * 2}px;
        grid-row-gap: ${gutter * 2}px;
        ${
          responsive
            ? `
          grid-template-columns: repeat(auto-fill,minmax(100% ,1fr));
          @media (min-width: ${breakpoints.md}) {
          grid-template-columns: repeat(auto-fill,minmax(calc(calc(100% / ${cols === 1 ? 1 : cols + 1}) - ${gutter}px), 1fr));
        }`
            : `grid-template-columns: repeat(auto-fill,minmax(calc(calc(100% / ${cols === 1 ? 1 : cols + 1}) - ${gutter}px), 1fr));`
        }
      `
}

export function gridCol(col: number) {
  return `
    grid-column: span ${col};
  `
}

export const buttonStyles = css`
  background: rgba(39, 33, 30, 1);
  transition: all 0.1s 0s ease-in-out;
  outline: none;
  border: none;

  &:hover {
    background: rgba(39, 33, 30, 0.7);
  }
`

export const containerPaddings = `
  padding: 0 ${spacings.s};

  ${respondTo.sm`
   padding: 0 ${spacings.l};
  `}
`

export const containerMargins = `
  margin-right: ${spacings.s};
  margin-left: ${spacings.s};

  ${respondTo.sm`
   margin-right: ${spacings.l};
   margin-left: ${spacings.l};
  `}
`
