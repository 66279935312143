import React, { useState } from 'react'
import styled from 'styled-components'

import Link from '../Link'
import logo from '../../assets/images/logo.png'
import HeaderNav from './HeaderNav'

import { colors, container, flexRow, respondTo, spacings, zIndex } from '../../styles'

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  ${zIndex.header};
  padding: ${spacings.m};
  background: ${colors.white};

  ${respondTo.md`
    padding: ${spacings.s} ${spacings.m} ${spacings.s} 0;
  `} &.opened {
    transition: background 0s linear 0.1s;
    background-color: transparent;
    ${respondTo.md`
       background-color: ${colors.primary};
     `};
  }
`

const HeaderContentWrapper = styled.div`
  ${respondTo.md`
    margin-left: -48px;
  `};
`

const HeaderContent = styled.nav`
	${container()} ${flexRow('space-between', 'center')} ${respondTo.sm`${flexRow('space-between', 'center')}`};
`

const Logo = styled.img`
  height: auto;
  width: 250px;
  margin: 0;
  ${respondTo.md`
		width: 350px;
		margin: 0 ${spacings.l};
	`}
`

export interface MenuItem {
  id: string
  title: string
  link_to: string
}

const primaryMenu: MenuItem[] = [
  {
    id: 'home',
    title: 'Home',
    link_to: '/'
  },
  {
    id: 'assesments',
    title: 'Assessments',
    link_to: '/#assesments'
  },
  {
    id: 'referralRequirements',
    title: 'Referrals',
    link_to: '/#referal-requirements'
  },
  {
    id: 'fees',
    title: 'Fees',
    link_to: '/#fees'
  },
  {
    id: 'fundingAndRebates',
    title: 'Funding & Rebates',
    link_to: '/#funding-and-rebates'
  },
  {
    id: 'about',
    title: 'Our Story',
    link_to: '/#about'
  },
  {
    id: 'contactUs',
    title: 'Contact Us',
    link_to: '/#contact-us'
  }
]

const Header = () => {
  const [isOpened, setIsOpened] = useState(false)
  return (
    <HeaderContainer className={isOpened && 'opened'}>
      <HeaderContentWrapper>
        <HeaderContent>
          <Link to="/">
            <Logo src={logo} alt="Logo" />
          </Link>
          <HeaderNav primaryMenu={primaryMenu} toggleOpened={setIsOpened} />
        </HeaderContent>
      </HeaderContentWrapper>
    </HeaderContainer>
  )
}

export default Header
