import React from 'react'
import styled from 'styled-components'
import { containerPaddings, container, colors, flexRow, spacings, typo } from '../styles'

const FooterWrapper = styled.footer`
  ${container()};
  ${containerPaddings};
  margin-bottom: ${spacings.m};
  margin-top: ${spacings.xl};
`

const FooterContainer = styled.footer`
  background: ${colors.brown};
  padding: ${spacings.m} 0;
`

const FooterCopyright = styled.div`
	${flexRow('center')} ${typo.font(14, 400, '24px', '0.4px')} color: ${colors.white};
	margin-top: ${spacings.s};
`

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <FooterWrapper>
        <FooterCopyright>© 2020 by The Brain & Behaviour Clinic</FooterCopyright>
      </FooterWrapper>
    </FooterContainer>
  )
}

export default Footer
