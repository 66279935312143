import * as React from 'react'
import LayoutRoot from '../components/LayoutRoot'
import Hero from '../components/Hero'
import { graphql } from 'gatsby'
import { FluidObject } from 'gatsby-image'
import styled from 'styled-components'
import { colors, spacings } from '../styles'
import { Container, ContainerInner } from '../components/Container'
import SubHeadline from '../components/SubHeadline'
import Text from '../components/Text'
import Divider from '../components/Divider'
import CardsWithDescriptionGrid, { CardWithDescription } from '../components/CardsWithDescriptionGrid'
import { respondTo, typo, gridCol, grid, getSpace } from '../styles'
import CardWithCover from '../components/CardWithCover'
import BgImage from '../components/BgImage'
import FeedbackForm from '../components/Form'
import ReactMarkdown from 'react-markdown'

interface IndexProps {
  data: {
    image: {
      id: string
      childImageSharp: {
        fluid: FluidObject
      }
    }
    feature_1: {
      id: string
      childImageSharp: {
        fluid: FluidObject
      }
    }
    feature_2: {
      id: string
      childImageSharp: {
        fluid: FluidObject
      }
    }
    feature_3: {
      id: string
      childImageSharp: {
        fluid: FluidObject
      }
    }
    feature_4: {
      id: string
      childImageSharp: {
        fluid: FluidObject
      }
    }
    section_cover_1: {
      id: string
      childImageSharp: {
        fluid: FluidObject
      }
    }
    section_cover_2: {
      id: string
      childImageSharp: {
        fluid: FluidObject
      }
    }
    section_cover_3: {
      id: string
      childImageSharp: {
        fluid: FluidObject
      }
    }
    owner_image: {
      id: string
      childImageSharp: {
        fluid: FluidObject
      }
    }
    strapi: {
      homePage: {
        assesments_title: string
        assesments_description: string
        assesments_cards_title: string
        card_1_title: string
        card_1_description: string
        card_2_title: string
        card_2_description: string
        card_3_title: string
        card_3_description: string
        card_4_title: string
        card_4_description: string
        fee_1_description: string
        fee_1_price: string
        fee_1_title: string
        fee_2_description: string
        fee_2_price: string
        fee_2_title: string
        fee_3_description: string
        fee_3_price: string
        fee_3_title: string
        fee_4_description: string
        fee_4_price: string
        fee_4_title: string
        fees_note: string
        fees_title: string
        funding_and_rebates_description: string
        funding_and_rebates_title: string
        get_in_touch_description: string
        hero_title: string
        get_in_touch_title: string
        our_story_description: string
        our_story_title: string
        referral_requirements_description: string
        referral_requirements_title: string
      }
    }
  }
}

const GreenSection = styled.section`
  background: ${colors.mint};
  padding: ${spacings.xxl} 0;
  ${respondTo.md`
    padding: ${spacings.xxxxl} 0;
  `};
`

const GreySection = styled.section`
  padding: ${spacings.xxl} 0;
  background: ${colors.grey};
  p {
    ${typo.font(16, 300, '1.2', 'normal')};
    white-space: pre-wrap;
    color: ${colors.lightBrown};
    margin: 0;
    margin-bottom: ${spacings.m};
  }
`

const SmallHeadline = styled(SubHeadline)`
  ${typo.font(18, 300, 'normal', 'normal')}
  ${respondTo.sm`
    ${typo.font(24, 300, 'normal', 'normal')}
  `}
`

const OwnerGrid = styled.div`
  margin-top: ${spacings.xs};
  padding: ${spacings.xs} 0;
  ${respondTo.md`
    ${grid(4)};
    margin-top: ${spacings.xxl};
    padding: ${spacings.xxxl} 0;
  `};
`

const OwnerCard = styled.div`
  ${SubHeadline}, p {
    text-align: center;
  }
  ${gridCol(2)};
  background: ${colors.white};
  box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.24), 0 1.5px 6px rgba(0, 0, 0, 0.12);
  padding: ${spacings.m} ${spacings.m} ${spacings.s} ${spacings.m};
  ${respondTo.md`
    ${SubHeadline}, p {
      text-align: center;
    }
    margin-left: ${getSpace(35)};
    padding: ${spacings.xxl} ${spacings.xxl} ${spacings.s} ${spacings.xxl};
  `} position: relative;
  border-radius: 0 16px 0 16px;
  z-index: 1;
`

const MobileDivider = styled(Divider)`
  display: flex;
  ${respondTo.md`
      display: none;
    `}
`

const UnderlineLink = styled.a`
  ${typo.font(16, 300, '1.2', 'normal')};
  color: ${colors.black};
  &:hover {
    color: ${colors.black};
    text-decoration: underline;
  }
`

const FormTitle = styled(SubHeadline)`
  color: ${colors.lightBrown};
`

const FormText = styled(Text)`
  color: ${colors.black};
`

const OwnerCardImage = styled.div`
  margin-bottom: ${spacings.l};
  display: block;
  ${respondTo.md`
    display: none;
  `};
`

const OwnerImage = styled.div`
  ${gridCol(2)};
  position: relative;
  display: none;
  ${respondTo.md`
    display: block;
  `} box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.24), 0 1.5px 6px rgba(0, 0, 0, 0.12);
  transform: translate3d(-100px, -40px, 0);
`

const Index: React.FC<IndexProps> = ({ data }) => {
  const { homePage } = data.strapi
  const cards: CardWithDescription[] = [
    {
      image: data.feature_1,
      title: homePage.card_1_title,
      description: homePage.card_1_description
    },
    {
      image: data.feature_2,
      title: homePage.card_2_title,
      description: homePage.card_2_description
    },
    {
      image: data.feature_3,
      title: homePage.card_3_title,
      description: homePage.card_3_description
    },
    {
      image: data.feature_4,
      title: homePage.card_4_title,
      description: homePage.card_4_description
    }
  ]

  const feesCards: CardWithDescription[] = [
    {
      title: homePage.fee_1_title,
      description: homePage.fee_1_description,
      footer: homePage.fee_1_price
    },
    {
      title: homePage.fee_2_title,
      description: homePage.fee_2_description,
      footer: homePage.fee_2_price
    },
    {
      title: homePage.fee_3_title,
      description: homePage.fee_3_description,
      footer: homePage.fee_3_price
    },
    {
      title: homePage.fee_4_title,
      description: homePage.fee_4_description,
      footer: homePage.fee_4_price
    }
  ]

  return (
    <LayoutRoot>
      <Hero title={homePage.hero_title} image={data.image} />
      <GreenSection id="assesments">
        <Container>
          <ContainerInner>
            <SubHeadline>{homePage.assesments_title}</SubHeadline>
            <Divider />
            <Text>{homePage.assesments_description}</Text>
          </ContainerInner>
        </Container>
      </GreenSection>
      <GreySection>
        <Container>
          <ContainerInner>
            <SmallHeadline>{homePage.assesments_cards_title}</SmallHeadline>
          </ContainerInner>
        </Container>
        <ContainerInner>
          <CardsWithDescriptionGrid columns={4} cards={cards} />
        </ContainerInner>
      </GreySection>
      <CardWithCover id="referal-requirements" image={data.section_cover_1} imagePosition="right">
        <SubHeadline align="left">{homePage.referral_requirements_title}</SubHeadline>
        <MobileDivider />
        <ReactMarkdown source={homePage.referral_requirements_description} />
      </CardWithCover>
      <GreySection id="fees">
        <SubHeadline>{homePage.fees_title}</SubHeadline>
        <MobileDivider />
        <Container>
          <ContainerInner>
            <CardsWithDescriptionGrid mobileGrid={true} columns={4} cards={feesCards} />
            <ReactMarkdown source={homePage.fees_note} />
          </ContainerInner>
        </Container>
      </GreySection>

      <CardWithCover id="funding-and-rebates" image={data.section_cover_2} imagePosition="left">
        <SubHeadline align="left">{homePage.funding_and_rebates_title}</SubHeadline>
        <MobileDivider />
        <ReactMarkdown source={homePage.funding_and_rebates_description} />
      </CardWithCover>

      <GreySection>
        <Container>
          <ContainerInner>
            <OwnerGrid id="about">
              <OwnerCard>
                <OwnerCardImage>
                  <BgImage maxWidth={2} maxHeight={3} fluid={data.owner_image.childImageSharp.fluid} />
                </OwnerCardImage>
                <SubHeadline align="left">{homePage.our_story_title}</SubHeadline>
                <ReactMarkdown source={homePage.our_story_description} />
              </OwnerCard>
              <OwnerImage>
                <BgImage maxWidth={1} maxHeight={1} fluid={data.owner_image.childImageSharp.fluid} />
              </OwnerImage>
            </OwnerGrid>
          </ContainerInner>
        </Container>
      </GreySection>

      <CardWithCover id="contact-us" image={data.section_cover_3} imagePosition="right">
        <FormTitle align="left">{homePage.get_in_touch_title}</FormTitle>
        <MobileDivider />
        <ReactMarkdown source={homePage.get_in_touch_description} />
        <FeedbackForm />
      </CardWithCover>
    </LayoutRoot>
  )
}

export default Index

export const pageQuery = graphql`
  query IndexQuery {
    image: file(relativePath: { eq: "hero.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    feature_1: file(relativePath: { eq: "feature_1.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 75, maxHeight: 750) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    feature_2: file(relativePath: { eq: "feature_2.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 100, maxHeight: 750) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    feature_3: file(relativePath: { eq: "feature_3.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 100, maxHeight: 750) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    feature_4: file(relativePath: { eq: "feature_4.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 100, maxHeight: 750) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    section_cover_1: file(relativePath: { eq: "section_cover_1.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    section_cover_2: file(relativePath: { eq: "section_cover_2.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    section_cover_3: file(relativePath: { eq: "section_cover_3.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    owner_image: file(relativePath: { eq: "owner_image.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    strapi {
      homePage {
        assesments_title
        assesments_description
        assesments_cards_title
        card_1_title
        card_1_description
        card_2_title
        card_2_description
        card_3_title
        card_3_description
        card_4_title
        card_4_description
        fee_1_description
        fee_1_price
        fee_1_title
        fee_2_description
        fee_2_price
        fee_2_title
        fee_3_description
        fee_3_price
        fee_3_title
        fee_4_description
        fee_4_price
        fee_4_title
        fees_note
        fees_title
        funding_and_rebates_description
        funding_and_rebates_title
        get_in_touch_description
        hero_title
        get_in_touch_title
        our_story_description
        our_story_title
        referral_requirements_description
        referral_requirements_title
        updated_at
      }
    }
  }
`
