import React, { useEffect, useState } from 'react'
import Styled, { FlattenSimpleInterpolation } from 'styled-components'
import ReactDOM from 'react-dom'
import { zIndex } from '../../styles'

interface ModalProps {
  extraCss: FlattenSimpleInterpolation
}

const ModalBlock = Styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: auto;
  background: rgba(255,255,255,.97);
  ${zIndex.modal}
  transition: opacity .14s 0s ease-in-out,visibility 0s 0s linear;

  &.opened {
    opacity: 1;
    visibility: visible;
  }

  ${(props: ModalProps) => props.extraCss}
`

const Modal: React.FC<ModalProps> = ({ children, extraCss }) => {
  const el = document.createElement('div')
  const [isOpened, setIsOpened] = useState(true)

  useEffect(() => {
    document.body.appendChild(el)
    document.body.classList.add('overflow-hidden')
    return () => {
      setIsOpened(false)
      document.body.classList.remove('overflow-hidden')
      document.body.removeChild(el)
    }
  }, [])

  return ReactDOM.createPortal(
    <ModalBlock extraCss={extraCss} className={isOpened && 'opened'}>
      {children}
    </ModalBlock>,
    el
  )
}

export default Modal
