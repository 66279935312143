import React from 'react'
import { FluidObject } from 'gatsby-image'
import styled from 'styled-components'
import { container, flexRow, colors, respondTo, spacings, typo } from '../styles'
import Img from 'gatsby-image/withIEPolyfill'

const HeroImage = styled(props => <Img {...props} />)`
  position: absolute !important;
  width: 100%;
  height: 100%;
`

const HeroSection = styled.section`
  position: relative;
  img {
    object-position: 60% 50% !important;
  }

  ${respondTo.md`
  img {
    object-position: 50% 50%!important;
  }
  `}
`

const HeroContent = styled.div`
	${container()} padding: ${spacings.m} ${spacings.s};
	${flexRow(null, 'center')}
  min-height: 80vh;
	${respondTo.xs`
    padding: 0 ${spacings.l};
  `} position: relative;


  ${respondTo.md`
    ${flexRow('center', 'center')}
    min-height: 820px;
  `}
`

const HeroTitle = styled.h1`
  ${typo.font(20, 300)}
  color: ${colors.sherwood};
  white-space: pre-wrap;
	margin: ${spacings.l} 0;

  max-width: 150px;
  text-align: right;

	${respondTo.sm`
    ${typo.font(40, 300)}
    line-height: 1.5;
    max-width: 260px;
  `};

  ${respondTo.sm`
    margin: ${spacings.getSpace(20)} 300px ${spacings.l} 0;
  `}
`

export interface HeroProps {
  image: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
  title: string
}

const Hero: React.FC<HeroProps> = ({ title, image }) => {
  return (
    <HeroSection>
      {image && <HeroImage fluid={{ ...image.childImageSharp.fluid, aspectRatio: 9 / 15 }} />}
      <HeroContent>
        <HeroTitle>{title}</HeroTitle>
      </HeroContent>
    </HeroSection>
  )
}

export default Hero
