import styled from 'styled-components';

import { colors, respondTo, spacings, typo } from '../styles';

const SubHeadline =
	styled.h3 <
	{ align: 'left' } >
	`
	${typo.font(26, 300, 'normal', 'normal')};
	margin-bottom: ${spacings.xxs};
	color: ${colors.lightBrown};
  font-weight: 400;

  ${(props) => `text-align: ${props.align || 'center'};`}

	${respondTo.sm`
    ${typo.font(30, 300, 'normal', 'normal')}
  `} margin-bottom: ${spacings.m};
`;

export default SubHeadline;
