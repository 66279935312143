import React, { useState } from 'react'
import styled from 'styled-components'
import { isFunction } from 'lodash'

import Link from '../Link'
import HeaderModal from './HeaderModal'
import { colors, flexColumn, flexRow, respondTo, spacings, typo } from '../../styles'
import { MenuItem } from './Header'

const HeaderNavList = styled.ul`
  padding: 0;
  margin: 0;
  display: none;
  ${respondTo.md`
    ${flexRow(null, 'center')}
  `};
`

const HeaderNavListItem = styled.li`
  ${typo.font(12, 400, null, '2px')} list-style: none;

  .link {
    display: block;
    color: ${colors.sherwood};
    padding: ${spacings.xxs} ${spacings.xs};
    text-decoration: none;
    position: relative;
    box-sizing: border-box;

    &:before {
      content: '';
      left: 12px;
      position: absolute;
      box-sizing: border-box;
      display: block;
      width: 0;
      height: 1px;
      background-color: ${colors.sherwood};
      bottom: 0px;
      transition: all 0.2s linear;
    }

    &:hover,
    &.active {
      &:before {
        width: calc(100% - 24px);
      }
    }
  }
`

const Burger = styled.div`
  ${flexColumn('space-between')} height: 16px;
  width: 22px;
  cursor: pointer;
  position: relative;
  pointer-events: auto;
  ${respondTo.md`
    display: none;
  `};
`

const Bar = styled.span`
  height: 2px;
  position: absolute;
  width: 100%;
  background: ${colors.sherwood};
  transition: transform .1s 0s ease-in-out,top .1s .1s ease-in-out;
  transform-origin: 50% 50%;

  &:nth-child(1) {
    top: 0;
  }

  &:nth-child(2) {
    top: 7px;
    transition: opacity 0s .15s linear;
  }

  &:nth-child(3) {
    top: 14px;
  }

  .opened & {
      &:nth-child(1) {
        top: 7px;
        transform: rotate(45deg);
        transition: top .1s .1s ease-in-out,transform .1s .2s ease-in-out; v
      }

      &:nth-child(3) {
        top: 7px;
        transform: rotate(-45deg);
        transition: top .1s .1s ease-in-out,transform .1s .2s ease-in-out;
      }

      &:nth-child(2) {
        opacity: 0;
      }
  }
`

interface HeaderNavProps {
  toggleOpened: (opened: boolean) => void
  primaryMenu: MenuItem[]
}

const HeaderNav: React.FC<HeaderNavProps> = ({ toggleOpened, primaryMenu }) => {
  const [isOpened, setOpened] = useState(false)

  const onBurgerClick = () => {
    if (isFunction(toggleOpened)) {
      toggleOpened(!isOpened)
    }
    setOpened(!isOpened)
  }

  return (
    <nav>
      <Burger className={isOpened && 'opened'} onClick={onBurgerClick}>
        <Bar />
        <Bar />
        <Bar />
      </Burger>
      <HeaderNavList>
        {primaryMenu.map((item, i) => (
          <HeaderNavListItem key={i}>
            <Link to={item.link_to}>{item.title}</Link>
          </HeaderNavListItem>
        ))}
      </HeaderNavList>
      {isOpened && (
        <HeaderModal
          onClick={() => {
            toggleOpened(false)
            setOpened(false)
          }}
          primaryMenu={primaryMenu}
        />
      )}
    </nav>
  )
}

export default HeaderNav
