import * as React from 'react'
import styled from 'styled-components'
import Seo from './Seo'
import Header from './header/Header'
import '../styles/layout.scss'
import { flexColumn } from '../styles/mixins'
import Footer from './Footer'

const LayoutMain = styled.main`
  ${flexColumn()}
`

const LayoutRoot: React.FC = ({ children }) => (
  <>
    <Seo />
    <Header />
    <LayoutMain>{children}</LayoutMain>
    <Footer />
  </>
)

export default LayoutRoot
