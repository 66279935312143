import React from 'react'
import { FluidObject } from 'gatsby-image'
import styled from 'styled-components'
import { colors, grid, gridCol, getSpace, respondTo, spacings, breakpoints, gutter, typo } from '../styles'
import { Container, ContainerInner } from './Container'
import BgImage from './BgImage'
import SubHeadline from './SubHeadline'
import Text from './Text'

export interface CardWithCoverProps {
  image: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
  imagePosition: 'left' | 'right'
}

const Section = styled.section`
  background: ${colors.mint};
  padding: ${spacings.xxl} 0;
  position: relative;
  text-align: center;

  a {
    ${typo.font(16, 300, '1.2', 'normal')};
    color: ${colors.lightBrown};
    &:hover {
      color: ${colors.lightBrown};
      text-decoration: underline;
    }
  }

  ${SubHeadline} {
    text-align: center;
  }

  ${respondTo.md`
    ${SubHeadline} {
      text-align: left;
    }
    text-align: left;
    padding: ${getSpace(40)} 0;
  `};

  ul {
    padding: 0 0 0 15px;
    color: ${colors.lightBrown};
    margin: 0;
    margin-bottom: ${spacings.m};
    ${typo.font(16, 300, '1.2', 'normal')};
  }

  p {
    color: ${colors.lightBrown};
    margin: 0;
    margin-bottom: ${spacings.m};
    ${typo.font(16, 300, '1.2', 'normal')};
    white-space: pre-wrap;
  }
`

const Grid = styled.div`
  ${grid(2)};
`

const ImageContainer = styled.div<{ imagePosition: 'right' | 'left' }>`
  position: absolute;
  display: none;

  ${props =>
    props.imagePosition === 'right'
      ? `
  left: calc(50% + ${gutter}px);;
	top: 0;
	bottom: 0;
	right: 0;
  `
      : `
  right: calc(50% + ${gutter}px);
	top: 0;
	bottom: 0;
	left: 0;
  `}

  ${respondTo.md`
    display: block;
  `};
`

const Content = styled.div<{ imagePosition: 'left' | 'right' }>`
  ${props => {
    return props.imagePosition === 'right'
      ? `
        ${gridCol(1)}
      `
      : `
      ${gridCol(1)}
      @media (min-width: ${breakpoints.md}) {
        grid-column: 2/3;
      }
      `
  }}
`

const CardWithCover: React.FC<CardWithCoverProps> = ({ id, children, image, imagePosition }) => {
  return (
    <Section id={id}>
      <Container>
        <ContainerInner>
          <Grid>
            <Content imagePosition={imagePosition}>{children}</Content>
          </Grid>
        </ContainerInner>
      </Container>
      <ImageContainer imagePosition={imagePosition}>
        <BgImage fluid={image.childImageSharp.fluid} />
      </ImageContainer>
    </Section>
  )
}

export default CardWithCover
