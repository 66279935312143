import React, { useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import styled from 'styled-components'
import { buttonStyles, respondTo, container, typo, spacings, colors, grid, gridCol, flexColumn } from '../styles'
import axios from 'axios'

const Text = styled.p<{ invalid: boolean }>`
  ${typo.font(14, 400, '1.75em', '.4px')}
  color: ${colors.sherwood};
  margin: 0;
  ${({ invalid }) => invalid && `color: ${colors.danger}`}
`

const FormContainer = styled(Form)`
  ${container()}
  width: 100%;
  margin: ${spacings.xxl} auto;
`

const FormField = styled(Field)`
  ${typo.font(14, 400)}
  padding: 0 ${spacings.xs};
  height: 40px;
  border: 1px solid ${colors.black};
  border-radius: 2px;
  background: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  color: #000;
  outline: none;

  ${({ name, errorName, errors }) => {
    return errors[errorName || name] && `border-color: ${colors.danger}`
  }}

`

const FieldBlock = styled.div<{ cols: number }>`
  ${props => grid(props.cols || 1)}
  grid-row-gap: 0;

  &.invalid {
    ${Text} {
      color: ${colors.danger};
    }
  }
`

const FormBlock = styled.div`
  ${gridCol(1)}
  ${flexColumn()}
  margin-bottom: ${spacings.s};
  ${respondTo.md`
    margin-right: ${spacings.xxxxl};
  `}

`

const FormTextarea = styled(props => <FormField {...props} />)`
  height: 100px;
  min-height: 100px;
  resize: vertical;
`

const ButtonWrapper = styled.div`
  ${respondTo.md`
  margin-right: ${spacings.xxxxl};`}
`

const Button = styled.button`
    ${typo.font(14, 400, null, '2px')}
    ${buttonStyles}
    color: ${colors.white};
    width: 100%;
    padding: ${spacings.xs} ${spacings.l};
    margin-top: ${spacings.s};
`

const ErrorBlock = styled(ErrorMessage)``

const ErrorInner = styled.div`
  margin-top: ${spacings.xxs};
  padding: 0 ${spacings.xxs};

  ${typo.font(13, 400, '23px', '.4px')}

  span {
    color: ${colors.danger};
  }
`

const Co = (props: any) => (
  <ErrorInner {...props}>
    <span>{props.children}</span>
  </ErrorInner>
)

const Error = (props: any) => {
  return <ErrorBlock {...props} component={Co} />
}

const FeedbackForm = () => {
  const [success, setSuccess] = useState(false)
  return (
    <Formik
      initialValues={{
        email: '',
        name: '',
        subject: '',
        message: ''
      }}
      validate={values => {
        const errors: any = {}
        if (!values.email) {
          errors.email = 'Email is required'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = 'Invalid email address'
        }

        if (!values.name) {
          errors.name = 'Name is required'
        }

        if (!values.subject) {
          errors.subject = 'Subject is required'
        }

        if (!values.message) {
          errors.message = 'Message is required'
        }

        return errors
      }}
      onSubmit={(values, { setSubmitting }) => {
        axios
          .post('/feedback', values)
          .then(() => {
            setSubmitting(false)
            setSuccess(true)
          })
          .catch()
      }}
    >
      {({ isSubmitting, errors }) =>
        !success ? (
          <FormContainer>
            <FormBlock>
              <FormField placeholder="Name*" errors={errors} id="name" type="text" name="name" />
              <Error name="name" />
            </FormBlock>
            <FormBlock>
              <FormField placeholder="Email*" errors={errors} id="email" type="text" name="email" />
              <Error name="email" />
            </FormBlock>
            <FormBlock>
              <FormField placeholder="Subject*" errors={errors} id="subject" type="text" name="subject" />
              <Error name="subject" />
            </FormBlock>
            <FormBlock className={errors.message && 'invalid'}>
              <FormTextarea placeholder="Type your message here..." errors={errors} id="message" name="message" component="textarea" />
              <Error name="message" />
            </FormBlock>
            <ButtonWrapper>
              <Button type="submit" disabled={isSubmitting}>
                Submit
              </Button>
            </ButtonWrapper>
          </FormContainer>
        ) : (
          <Text>
            Thank you for submitting your information, <br />
            we will be contact as soon as possible. <br />
          </Text>
        )
      }
    </Formik>
  )
}

export default FeedbackForm
