import React from 'react'
import styled from 'styled-components'
import { Container } from './Container'
import { colors, flexRow, spacings } from '../styles'

const Line = styled.div`
  width: 42px;
  height: 3px;
  background-color: ${colors.white};
  margin: 0;
`

export const DividerWrapper = styled(Container)`
  margin: ${spacings.l} auto;
  ${flexRow('center')};
`

const Divider = ({ className }) => {
  return (
    <DividerWrapper className={className}>
      <Line />
    </DividerWrapper>
  )
}

export default Divider
