import styled from 'styled-components';
import Img from 'gatsby-image/withIEPolyfill';
import React, { Fragment } from 'react';
import { FluidObject } from 'gatsby-image';

const Image = styled((props) => <Img {...props} />)`
  width: 100%;
  height: 100%;
`;

const BgOverlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.1);
`;

export interface BgImageProps {
	fluid: FluidObject;
	withOverlay?: boolean;
	aspectRatio?: any;
	maxWidth?: number;
	maxHeight?: number;
}

const BgImage: React.FC<BgImageProps> = ({ fluid, withOverlay = true, aspectRatio, maxWidth, maxHeight }) => {
	if (maxHeight && maxWidth) {
		aspectRatio = maxHeight / maxWidth;
	} else {
		aspectRatio = null;
	}
	return (
		<Fragment>
			<Image fluid={{ ...fluid, ...aspectRatio ? { aspectRatio } : {} }} />
		</Fragment>
	);
};

export default BgImage;
