import React from 'react'
import styled, { css } from 'styled-components'
import { colors, flexColumn, respondTo, spacings, typo } from '../../styles'

import Modal from './Modal'
import Link from '../Link'
import { MenuItem } from './Header'

const modalStyles = css`
  ${flexColumn('center', 'center')} ${respondTo.md`
    display: none;
  `};
`

const ModalListItem = styled.div`
  ${typo.font(24, 300, '24px', '2px')} text-align: center;
  list-style: none;

  .link {
    color: ${colors.sherwood};
    padding: ${spacings.xs} 0;
    text-decoration: none;
    display: block;
    position: relative;
    box-sizing: border-box;

    &:before {
      content: '';
      left: 0;
      position: absolute;
      box-sizing: border-box;
      display: block;
      width: 0;
      height: 1px;
      background-color: ${colors.sherwood};
      bottom: 0px;
      transition: all 0.2s linear;
    }

    &:hover,
    &.active {
      &:before {
        width: 100%;
      }
    }
  }
`

interface HeaderModalProps {
  primaryMenu: MenuItem[]
  onClick: () => void
}

const HeaderModal: React.FC<HeaderModalProps> = ({ primaryMenu, onClick }) => {
  return (
    <Modal extraCss={modalStyles}>
      {primaryMenu.map((item, i) => (
        <ModalListItem onClick={onClick} key={i}>
          <Link to={item.link_to}>{item.title}</Link>
        </ModalListItem>
      ))}
    </Modal>
  )
}

export default HeaderModal
