import React from 'react'
import styled from 'styled-components'
import { CardWithDescription as CardWithDescriptionData } from './CardsWithDescriptionGrid'
import { gridCol, typo, spacings, colors, flexRow, flexColumn, respondTo } from '../styles'
import Img from 'gatsby-image/withIEPolyfill'
import FooterText from './Text'

export interface CardWithDescriptionProps extends CardWithDescriptionData {
  mobileGrid?: boolean
}

const Card = styled.div`
  position: relative;
  margin: 0;
  ${gridCol(1)};
  ${flexColumn('space-between')};
  background: ${colors.white};
  padding-bottom: ${spacings.s};
  border-radius: 0 16px 0 16px;
  overflow: hidden;
  box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.24), 0 1.5px 6px rgba(0, 0, 0, 0.12);
`

const Headline = styled.h3<{ mobileGrid?: boolean }>`
  ${respondTo.md`
    height: 50px;
    padding: 0 ${spacings.s};
    ${typo.font(18, 300, '1.5', 'normal')}
	`}
  margin: ${spacings.s} 0;
  text-align: center;
  ${flexRow('center', 'center')};
  ${props => (props.mobileGrid ? typo.font(14, 300, '1.5', 'normal') : typo.font(18, 300, '1.5', 'normal'))};
  color: ${colors.orange};
`

const Text = styled.p<{ mobileGrid?: boolean }>`
  flex-grow: 1;
  margin: 0;
  font-style: italic;
  text-align: center;
  color: ${colors.sherwood};
  font-size: 14px !important;
  ${respondTo.md`
    padding: 0 ${spacings.s};
    ${typo.font(14, 300, '24.5px', '0.4px')};
	`}
  white-space: pre-wrap;
`

const FooterTextBlock = styled(FooterText)`
  margin: ${spacings.s} 0 0 0 !important;
  font-size: 14px !important;
`

const CardWithDescription: React.FC<CardWithDescriptionProps> = ({ mobileGrid, title, description, image, footer }) => {
  let aspectRatio = 8 / 9
  return (
    <Card>
      {image && <Img fluid={{ ...image.childImageSharp.fluid, ...(aspectRatio ? { aspectRatio } : {}) }} />}
      <Headline mobileGrid={mobileGrid}>{title}</Headline>
      <Text mobileGrid={mobileGrid}>{description}</Text>
      {footer && <FooterTextBlock>{footer}</FooterTextBlock>}
    </Card>
  )
}

export default CardWithDescription
