import React from 'react'
import styled from 'styled-components'
import { typo, colors, spacings } from '../styles'

const Text = styled.p<{ align: 'left' }>`
  ${typo.font(16, 300, '1.2', 'normal')};
  white-space: pre-wrap;
  color: ${colors.lightBrown};
  margin: 0;
  text-align: center;
  margin-bottom: ${spacings.m};
  ${props => `text-align: ${props.align || 'center'};`};
`

export default Text
