import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink

interface LinkProps {
  to: string
  activeClassName?: string
  partiallyActive?: boolean
}

const Link: React.FC<LinkProps> = ({ children, to, activeClassName, partiallyActive, ...other }) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to)
  // Use Gatsby Link for internal links, and <a> for others
  if (internal && to.includes('#')) {
    return (
      <AnchorLink className="link" to={to} stripHash>
        {children}
      </AnchorLink>
    )
  } else if (internal && !to.includes('#')) {
    return (
      <GatsbyLink className="link" to={to} activeClassName="active" partiallyActive={false} {...other}>
        {children}
      </GatsbyLink>
    )
  }
  return (
    <a className="link" href={to} target="_blank" rel="noopener noreferrer" {...other}>
      {children}
    </a>
  )
}

export default Link
