import { css } from 'styled-components'

export const breakpoints = {
  xs: '480px',
  sm: '768px',
  md: '992px',
  lg: '1200px'
}

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg'

export const respondTo = Object.keys(breakpoints).reduce((accumulator, label) => {
  const breakpoint = breakpoints[label as Breakpoint]
  accumulator[label as Breakpoint] = (...args) => css`
    @media (min-width: ${breakpoint}) {
      ${css(...args)};
    }
  `
  return accumulator
}, {} as { [key in Breakpoint]: (...args: any[]) => string })
