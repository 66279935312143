import React from 'react'
import styled from 'styled-components'
import { FluidObject } from 'gatsby-image'
import { Container } from './Container'
import Card from './CardWithDescription'
import { spacings, breakpoints, gutter } from '../styles'

export interface CardWithDescription {
  image?: {
    id: string
    childImageSharp: {
      fluid: FluidObject
    }
  }
  title: string
  description: string
  footer?: string
}

export interface CardsWithDescriptionGridProps {
  columns: number
  cards: CardWithDescription[]
  mobileGrid?: boolean
}

const Grid = styled.div<{ columns: number; mobileGrid?: boolean }>`
  margin: ${spacings.xl} 0;
  display: grid;
  grid-gap: ${gutter * 2}px;
  grid-row-gap: ${gutter * 2}px;

  ${props => `grid-template-columns: repeat(auto-fill, minmax(calc(calc(100% / ${props.mobileGrid ? 3 : 1}) - ${gutter}px), 1fr))`};

  @media (min-width: ${breakpoints.sm}) {
    grid-template-columns: repeat(auto-fill, minmax(calc(calc(100% / ${2 + 1}) - ${gutter}px), 1fr));
  }

  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: repeat(auto-fill, minmax(calc(calc(100% / ${4 + 1}) - ${gutter}px), 1fr));
  }
`

const CardsWithDescriptionGrid: React.FC<CardsWithDescriptionGridProps> = ({ cards, columns, mobileGrid }) => (
  <Container wide={true}>
    <Grid columns={columns} mobileGrid={mobileGrid}>
      {cards.map((card, i) => (
        <Card mobileGrid={mobileGrid} key={i} {...card} />
      ))}
    </Grid>
  </Container>
)

export default CardsWithDescriptionGrid
