/* eslint-disable max-len */

export const colors = {
  primary: '#fcd4c9',
  sherwood: '#27211E',
  white: '#ffffff',
  black: '#000000',
  warning: '#cc3b3b',
  danger: '#bd0000',
  brown: 'rgba(47, 46, 46, 1)',
  mint: 'rgb(208, 219, 209)',
  lightBrown: '#65564E',
  grey: '#e4e6e8',
  orange: '#c88150'
}

export const fonts = {
  proximaNova: '"Proxima Nova", sans-serif',
  abel: '"Abel", sans-serif',
  sourceCode: '"Source Code Pro", monospace'
}

export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
}

export const widths = {
  md: 720,
  lg: 960,
  xl: 1140
}

export const dimensions = {
  fontSize: {
    regular: 16,
    large: 18
  },
  headingSizes: {
    h1: 2.441,
    h2: 1.953,
    h3: 1.563,
    h4: 1.25
  },
  lineHeight: {
    regular: 1.45,
    heading: 1.2
  },
  containerPadding: 1.5
}

export const heights = {
  header: 60
}
