import styled from 'styled-components'
import { container, containerPaddings } from '../styles'

export const Container = styled.div<{ wide?: boolean }>`
  width: 100%;
  ${props => container(props.wide)}
`

export const ContainerInner = styled.div`
  ${containerPaddings}
`
