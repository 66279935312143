const baseSpacing = 4

export const getSpace = (multiplier: number) => `${baseSpacing * multiplier}px`

export const spacings = {
  xxxs: getSpace(1),
  xxs: getSpace(2), // 8
  xs: getSpace(3), // 12
  s: getSpace(4), // 16
  m: getSpace(6), // 24
  l: getSpace(8), // 32
  xl: getSpace(10), // 40
  xxl: getSpace(12), // 48
  xxxl: getSpace(16), // 64,
  xxxxl: getSpace(20), // 80,
  getSpace
}
